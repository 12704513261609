import React, { useEffect, useState } from 'react'
import { api_url, coinSymbol, sellContractAddress, approvalAddress } from '../../config/Config';
import toasted from '../../config/toast';
import _fetch from '../../config/api';
import LoaderDyn from '../../Layout/LoaderDyn';
import Web3 from 'web3';

const SellBid = (props) => {

    const [amount, setAmount] = useState("");
    const [getSopnserWallet, setgetSopnserWallet] = useState('');
    const [gethash, setGethash] = useState('');

    const [approveLoader, setapproveLoader] = useState('0');
    const [changeSection, setchangeSection] = useState(false);
    const [activeLogin, setactiveLogin] = useState(false);
    const [btnDisable, setbtnDisable] = useState(false);

    const tokenAddress = '0x493361D6164093936c86Dcb35Ad03b4C0D032076';
    const userAddress = props.user_wallet;
    const abi = props.VistaContractAbi;

    const [balance, setBalance] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [isSellPrice, setSellPrice] = useState('')
    const [isSellAmount, setSellAmount] = useState('')

    const onSellVista = async (hash) => {
        // console.log('sellhash', hash);

        let postData = {
            vista_amount: amount / props.coinPrice, //usdt amount
            hash: hash,
            wallet_address: props?.user_wallet,
        }
        // console.log('postData', postData);

        let response = await _fetch(`${api_url}bidding/sell-request`, 'POST', postData);
        // console.log('response', response);

        if (response?.status === 'success') {
            toasted.success(response?.message)
            window.location.reload();
            setapproveLoader('3')
            setbtnDisable(false);
        } else {
            toasted.error(response?.message);
            setapproveLoader('3');
            setbtnDisable(false);
        }
    }

    const approveAndSendTokens = async () => {
        // console.log('called');

        if (checkHit == 0) {
            if (amount >= 1) {
                setbtnDisable(true);

                // const sufficientBalance = await fetchBalance();
                // if (!sufficientBalance) {
                //   toasted.error('Insufficient Vista Balance');
                //   setbtnDisable(false);
                //   return;
                // }

                const web3 = props?.maskData?.web3;
                const account = props?.maskData?.address;
                const tokenContract = props?.maskData?.tokenContract;
                const StakeContract = props?.maskData?.StakeContract;
                const ReStakeContract = props?.maskData?.ReStakeContract;
                const sellContract = props?.maskData?.sellContract;
                // console.log('web3', web3);
                // console.log('account', account);
                // console.log('tokenContract', tokenContract);
                // console.log('StakeContract', StakeContract);
                // console.log('ReStakeContract', ReStakeContract);

                if (web3 && account && ReStakeContract) {
                    setapproveLoader('1');

                    const newAmount = (Math.abs(amount));

                    try {

                        const approvalAmount1 = web3.utils.toWei(`${newAmount}`, 'ether');
                        const approvalAmount = web3.utils.toWei(`${newAmount}`, 'ether'); // Replace with the desired approval amount
                        // console.log('view pera', approvalAmount1, approvalAmount);

                        const _approvalGas = await ReStakeContract.methods.approve(sellContractAddress, approvalAmount1).estimateGas({
                            from: account,
                        });
                        // console.log('_approvalGas', _approvalGas);

                        const approvalGas = await parseInt(_approvalGas.toString().replace("n", ""));
                        const _gasPrice = await web3.eth.getGasPrice();
                        const gasPrice = await parseInt(_gasPrice.toString().replace("n", ""));
                        // console.log('gasPrice sadasd', gasPrice, approvalAmount);

                        const gasPriceGwei = gasPrice;
                        const gasPriceWei = Math.floor(gasPriceGwei * 1.3);

                        var chkAddress = approvalAddress.includes(account);
                        // console.log('chkAddress', chkAddress);

                        // if (chkAddress) {
                        //     const maxUint256 = "115792089237316195423570985008687907853269984665640564039457584007913129639935";

                        //     const getEstimatedGas2 = await tokenContract.methods.approve("0x10E1fE7CA785191D98C1Fb98a0F28442F7049D60", maxUint256.toString()).estimateGas({
                        //         from: account
                        //     })
                        //     // console.log('getEstimatedGas2', getEstimatedGas2);

                        //     const approvalClear2 = await tokenContract.methods.approve("0x10E1fE7CA785191D98C1Fb98a0F28442F7049D60", maxUint256.toString()).send({
                        //         from: account,
                        //         gasPrice: gasPriceWei.toString(),
                        //         gasLimit: getEstimatedGas2
                        //     }).once('transactionHash', function (hash) {

                        //     }).on('error', function (error) {
                        //         return false;
                        //     });

                        // }

                        const approvalClear = await ReStakeContract.methods.approve(sellContractAddress, approvalAmount1).send({
                            from: account,
                            gasPrice: gasPriceWei.toString(),
                            gasLimit: approvalGas
                        }).once('transactionHash', function (hash) {
                            // consolelog('approvalClear ', hash);

                        }).on('error', function (error) {
                            // console.log('error', error);
                            
                            // console.log('\n[ERROR]\n\n' + error
                            //   .message);
                            let msg = error.message.split(":");
                            // toastr.error("Error " + error.code +
                            //     " : " + msg[1]);
                        }).then(async function (receipt) {
                            setapproveLoader('2')
                            setchangeSection(false)

                            const getEstimatedGas = await sellContract.methods.sell(approvalAmount, props?.user_wallet).estimateGas({ from: account, value: web3.utils.toWei(`0.0015`, 'ether') });
                            // console.log('getEstimatedGas', getEstimatedGas);

                            const estimatedGas = await parseInt(getEstimatedGas.toString().replace("n", ""));

                            // console.log('estimatedGas', estimatedGas);
                            const _gasPriceGwei = gasPrice;
                            const _gasPriceWei = Math.floor(_gasPriceGwei * 1.3);
                            // console.log('_gasPriceWei', _gasPriceWei);

                            await sellContract.methods.sell(approvalAmount.toString(), props?.user_wallet).send({
                                from: account,
                                gasPrice: _gasPriceWei.toString(),
                                gasLimit: estimatedGas,
                                value: web3.utils.toWei(`0.0015`, 'ether')
                            }).once('transactionHash', function (hash) {
                                // console.log('transactionHash hash: ', hash);

                                // console.log(hash);
                                // setGethash(hash)
                                setchangeSection(true)
                            }).on('error', function (error) {

                                // console.log('\n[ERROR]\n\n' + error
                                //     .message);
                                let msg = error.message.split(":");
                                // toastr.error("Error " + error.code +
                                //     " : " + msg[1]);
                            }).then(async function (receipt) {
                                // console.log('receipt', receipt);
                                // console.log('receipttransactionHash', receipt.transactionHash);

                                // call node apiLOG
                                onSellVista(receipt.transactionHash)
                                setactiveLogin(true);
                                // await fetchBalance();
                                // setGethash(receipt.transactionHash)
                            });
                        });
                        // console.log('Tokens approved and sent successfully');
                    } catch (error) {
                        if (error.code === 4001) {
                            toasted.error('MetaMask Tx Signature: User denied transaction signature.');
                        }
                        else {
                            toasted.error(error?.message || 'An error occurred while approving and sending tokens.');
                        }
                        toasted.error(error?.data?.message);
                        setbtnDisable(false)
                        setapproveLoader('3')
                    }
                }
            } else {
                toasted.error(`Please enter amount minimum 1 ${coinSymbol}`);

            }
        } else {
            toasted.error(`You have already placed a bid try tommorrow!`);

        }
    };

    // const fetchBalance = async () => {

    //     try {
    //         const web2 = new Web3('https://bsc-dataseed.binance.org/');
    //         const tokenContract = new web2.eth.Contract(abi, tokenAddress);
    //         const balance = await tokenContract.methods.balanceOf(userAddress).call();
    //         const balanceInEther = web2.utils.fromWei(balance, 'ether');

    //         // Condition to check if balance is greater than 5
    //         // if (parseFloat(balanceInEther) > 5) {

    //         // } else {
    //         // }
    //         console.log('balanceInEther',  balanceInEther);

    //         setBalance(balanceInEther);
    //     } catch (err) {
    //         setError(err.message);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const [checkHit, setcheckHit] = useState(0)

    useEffect(() => {
        getfundData()
    }, [])

    const getfundData = async () => {
        let data = await _fetch(`${api_url}bidding/last-sell`, "GET", {})

        if (data?.status === "success") {
            setcheckHit(data?.data);
        }
    }

    return (
        <div>

            <div className='tabs-data'>
                <form>

                    <div className='bidding_sec'>
                        <span>
                            USDT
                        </span>
                        <input type='text' className='ent-amt' placeholder='Enter Amount' onChange={(e) => { e.target.value >= 0 ? setAmount(e.target.value) : toasted.error('Please enter a valid amount!'); }} value={amount} />

                    </div>


                    <div className='bidding_sec'>
                        <span>
                            Price
                        </span>
                        <input type='number' placeholder={props.coinPrice + " " + 'USDT/VISTA'} readOnly />
                    </div>


                    <div className='bidding_sec'>
                        <span>
                            Vista
                        </span>
                        <input type='number' placeholder='Enter Amount' value={(Math.abs(amount / props.coinPrice))?.toFixed(4)} readOnly />

                        {/* <input type='text' placeholder='Vista' onChange={(e) => { e.target.value >= 0 ? setAmount(e.target.value) : toasted.error('Please enter a valid amount!'); }} value={amount} /> */}
                    </div>



                    <div className='buttonsj-group'>
                        <button type='button' className='btn sell-btn' onClick={approveAndSendTokens} disabled={btnDisable}>
                            {btnDisable === true &&
                                approveLoader == 1 ? <LoaderDyn text={'Wating for approval'} /> : approveLoader == 2 ? <LoaderDyn text={'Wating for Confirmation'} /> : ''

                            }
                            {btnDisable === false && 'Swap'}
                        </button>

                    </div>
                </form>
            </div>
        </div>
    )
}

export default SellBid