import React, { useEffect, useRef, useState } from 'react';
// import NorthIcon from '@mui/icons-material/North';
// import SouthIcon from '@mui/icons-material/South';
import Marquee from "react-fast-marquee";
// import ProgressBar from 'react-bootstrap/ProgressBar';
// import Slider from '@mui/material/Slider';
import profit3 from '../../Assets/Image/profit3.png';
import profit4 from '../../Assets/Image/profit4.png';
import profit5 from '../../Assets/Image/profit5.png';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import team_vista from '../../Assets/Image/teamMember2.png';
import overview102 from '../../Assets/Image/mybussiness.png';
// import btc_img from '../../Assets/Image/btc_img.png';
// import eth from '../../Assets/Image/eth.webp';
// import xrp from '../../Assets/Image/xrp.webp';
import income from '../../Assets/Image/totalincome.png';
import usd from '../../Assets/Image/incomes.png';
// import bnb_img from '../../Assets/Image/bnb_img.png';
import profit2 from '../../Assets/Image/staks.png';
import wallet from '../../Assets/Image/rewards.png';
import bi from '../../Assets/Image/bi.jpg';
import bi1 from '../../Assets/Image/bi1.jpg';
import bi2 from '../../Assets/Image/bi2.jpg';
import bi3 from '../../Assets/Image/bi3.jpg';
import hour24 from '../../Assets/Image/hour24.png';
import { api_url, title, titleDescription, website } from '../../config/Config';
import { Chart } from "react-google-charts";
// import Table from 'react-bootstrap/Table';
import logo_2 from '../../Assets/Image/logo_2.png';
import _fetch from '../../config/api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import QRCode from 'qrcode.react';
// import scan_code from '../../Assets/Image/scan_code.png';
import 'react-responsive-modal/styles.css';
import toasted from '../../config/toast';
// import { ToastContainer } from 'react-toastify';
// import Modal from 'react-responsive-modal';
// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// import light_banner1 from '../../Assets/Image/light_banner1.png';
import dateformat from "dateformat";
import { ColorRing } from 'react-loader-spinner';
import { VolumeUpRounded } from '@mui/icons-material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import StakeCoin from './StakeCoin';
import RestakeCoin from './RestakeCoin';
import SellBid from './SellBid';
// import LoaderDyn from '../../Layout/LoaderDyn';
import ReactPaginate from 'react-paginate';
import BuyBid from './BuyBid';
import { Button } from 'react-bootstrap';


import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';


const Dashboard = (props: any) => {


  const [show, setShow] = useState(false);

  // Handle the close button click
  const handleClose = () => setShow(false);

  const leftBalance = localStorage.getItem('leftCoinBalance');

  const totalLeftBalance = leftBalance ? parseFloat(leftBalance) : 0;

  // useEffect(() => {
  // }, []);

  // const abortController = new AbortController();
  // const signal = abortController.signal;
  // console.log('signal---', signal);

  const [sponsorId, setSponsorId] = useState('');
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [open1, setOpen1] = useState(false);

  const onOpenModal1 = () => {
    setOpen1(true);
  }
  const onCloseModal1 = () => setOpen1(false);
  interface BannerDataType {
    banner_status: boolean;
    description: string;
    banner: string;
  }

  const [loading, setLoading] = useState(true);
  const [fundValues, setFundValues] = useState([]);
  const [summitAchiever, setsummitAchiever] = useState({ user: 0, business: 0 });
  const [coreAchievers, setCoreAchievers] = useState({ rank: '', totalarchievers: 0, totalrequiredbusiness: 0, pendingArchievers: 0, selfBusiness: { business: { firstleg: 0, secondleg: 0, thirdleg: 0 } } });

  const [message, setMessage] = useState(true);

  const [bannerData, setBannerData] = useState({ banner_status: "", description: "", banner: "" });
  const [newsData, setNewsData] = useState({ title: "", description: "", status: "" });

  const [teamBusinesses, setteamBusinesses] = useState({ direct_business: 0, team_business: 0 });

  const [userActive, setUserActive] = useState(false)
  const [active_status, setactive_status] = useState({ sponsor_id: "", wallet_address: "", user_info: { total_package: "" }, created_at: "", active_status: false });

  const [allUserData, setAllUserData] = useState({ royalIncome: '', diffenceIncome: "", rewardIncome: '', totalIncome: "", avaliableIncome: "", TotalDeposit: "", AvaliableDeposit: "", directIncome: "", levelIncome: "", poolIncome: "", roiIncome: "", royaltyIncome: "", userInfo: { user_info: { firstName: "", lastName: "", name: "", income_limit: "", income_earn: "", package: { current_package: "", previous_package: "", total_package: "" } } } });
  const [userRank, setUserRank] = useState({ rank: 0, rank_name: "" });
  const [bussinessData, setBussinessData] = useState({ firstleg: 0, secondleg: 0, thirdleg: 0 });
  const [totalTeam, settotalTeam] = useState();
  const [income_info, setincome_info] = useState({
    "staking_incentive": '0',
    "level_business_incentive": '0',
    "direct_business_incentive": '0',
    "permanent_reward": '0',
    "royality_incentive": '0',
    "difference_business_incentive": '0'
  });


  const [getArchieverData, setGetArchieverData] = useState({ soft_status: '' });
  const [archieverPhone, setArchieverPhone] = useState();
  const [seatno, setSeatno] = useState();

  const [starterPackage, setStarterPackage] = useState({ title: "", package_range: "", _id: "" });

  const [amount, setAmount] = useState("");

  const [stakedAmount, setStakedAmount] = useState("");
  const [checkArchiever, setCheckArchiever] = useState();
  const [weekTotalIncome, setWeekTotalIncome] = useState([]);
  const [activationData, setActivationData] = useState({ type: '', activate_date: '' });
  const [timer, setTimer] = useState(30 * 24 * 60 * 60);

  const [rankArray, setRankArray] = useState([

    { "business": 2500, "total_business": 2500, "rank_name": "Beginner", "rank_level": 1, "amount": 10 },
    { "business": 5000, "total_business": 7500, "rank_name": "Apprentice", "rank_level": 2, "amount": 25 },
    { "business": 15000, "total_business": 22500, "rank_name": "Journeyman", "rank_level": 3, "amount": 60 },
    { "business": 50000, "total_business": 72500, "rank_name": "Expert", "rank_level": 4, "amount": 150 },
    { "business": 100000, "total_business": 172500, "rank_name": "Master", "rank_level": 5, "amount": 350 },
    { "business": 300000, "total_business": 472500, "rank_name": "Elite", "rank_level": 6, "amount": 1000 },
    { "business": 1500000, "total_business": 1972500, "rank_name": "Champion", "rank_level": 7, "amount": 5200. },
    { "business": 5000000, "total_business": 6972500, "rank_name": "Grandmaster", "rank_level": 8, "amount": 17000 },
    { "business": 7500000, "total_business": 14472500, "rank_name": "Legend", "rank_level": 9, "amount": 30000 },
    { "business": 10000000, "total_business": 24472500, "rank_name": "Supreme", "rank_level": 10, "amount": 50000 },
  ]);

  const rankLevel = userRank?.rank;

  const nextRank = rankArray?.[rankLevel]?.rank_name;

  const MaxKingMaker = (rankArray?.[rankLevel]?.business) * 0.4;
  const MaxSecondLeg = (rankArray?.[rankLevel]?.business) * 0.3;
  const MaxOther = (rankArray?.[rankLevel]?.business) * 0.3;
  let PreviousMaxKingMaker = 0;
  let PreviousMaxSecondLeg = 0;
  let PreviousMaxOther = 0;
  if (rankLevel > 0) {
    const previousRank = rankLevel - 1;

    PreviousMaxKingMaker = (rankArray?.[previousRank]?.total_business) * 0.4;
    PreviousMaxSecondLeg = (rankArray?.[previousRank]?.total_business) * 0.3;
    PreviousMaxOther = (rankArray?.[previousRank]?.total_business) * 0.3;
  }
  const RankRoyality = (rankArray?.[rankLevel]?.amount);


  let location = useLocation();
  const navigate = useNavigate();
  const auth = localStorage.getItem('auth');

  const copyToClipboard = (address: any) => {

    var textField = document.createElement('textarea');
    textField.innerText = address;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toasted.success('Address copied successfull!');
  }

  useEffect(() => {

    // if (api_called !== "true") {
    localStorage.setItem("api_called", 'true');

    // return () => {

    // if (auth === "true") {
    (async () => {
      // setLoading(true);

      const data1 = await _fetch(`${api_url}user/userInfo/`, "GET", {});
      if (data1.status === 'success') {
        setLoading(false);

        if (data1?.data?.data) {
          localStorage.setItem("api_called", 'false');

          setLoading(false);
          setincome_info(data1?.data?.data?.income_info)
          setSponsorId(data1?.data?.data?.username);
          setUserRank(data1?.data?.data?.user_info);
          setteamBusinesses(data1?.data?.data?.business);
          setactive_status(data1?.data?.data);
          setUserActive(data1?.data.data.active_status);
          setLoading(false);

        }
      } else {
        // localStorage.clear();
        // navigate("/login");
      }
    })();


    // }

    // return () => {
    //   localStorage.setItem("api_called", 'false');
    // };
    // }
  }, [location.pathname]);

  useEffect(() => {

    // if (api_called !== "true") {
    // if (!sponsorId) {
    // return () => {
    getfundData();
    getUserData();
    getBannerData();
    getNewsData();
    getPackage();
    getCoinPrice();
    getActivationDetails()
    getOrders()
    getBusinessData()
    // getsummitAchiever()
    getCoreAchiever();
    checkExpoArchiever();
    getWeekTotalIncome();
    getREstakeData();
    getActivationData();
    // }
    // }
  }, []);

  const getActivationData = async () => {
    setLoading(true);
    let res = await _fetch(`${api_url}report/activation-detail`, "GET", {});

    if (res?.status === "success") {
      setLoading(false);
      setActivationData(res?.data?.data[0]);

      const activateDate = new Date(res?.data?.data[0]?.activate_date);
      const specificStartDate = new Date('2024-10-01');

      if (activateDate >= specificStartDate) {
        const targetDate = new Date(activateDate);
        targetDate.setDate(activateDate.getDate() + 30);

        const now = new Date();
        const timeDifference = Math.max(targetDate.getTime() - now.getTime(), 0);
        setTimer(Math.floor(timeDifference / 1000));
      } else {
        setTimer(0);
      }
    }
  };

  useEffect(() => {
    if (activationData?.type === 'activate' && timer > 0 && Number(income_info?.direct_business_incentive) < 5000) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 0) {
            clearInterval(interval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    } else if (Number(income_info?.direct_business_incentive) >= 5000) {
      setTimer(0);
    }
  }, [activationData, timer, income_info?.direct_business_incentive]);

  const formatTime = (seconds: any) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${days}d ${hours}h ${minutes}m ${secs}s`;
  };


  const checkExpoArchiever = async () => {
    let res = await _fetch(`${api_url}sponsor/check-archiever`, "GET", {});
    if (res?.status === "success") {

      if (res?.data?.username != '') {

        localStorage.setItem('archieverusername', res?.data?.username);
        setGetArchieverData(res?.data);
        setSeatno(res?.data?.seatno);
        setArchieverPhone(res?.data?.phoneNumber);
        setShow(true);
      }
    }
  }
  let checkArchieverUser = localStorage.getItem('archieverusername');

  const getBusinessData = async () => {
    let res = await _fetch(`${api_url}profile/business`, "GET", {})

    if (res?.status === "success") {
      setBussinessData(res?.business);
      // console.log('res?.data?.business',res?.business);


    }
  }

  const getfundData = async () => {
    let data = await _fetch(`${api_url}fund/getfund?limit=5`, "GET", {})

    if (data?.status === "success") {
      setFundValues(data?.data);
    }
  }

  // const getsummitAchiever = async () => {
  //   let data = await _fetch(`${api_url}network/summit-achiever`, "GET", {})

  //   if (data?.status === true) {
  //     setsummitAchiever(data?.data);
  //   }
  // }

  const getCoreAchiever = async () => {
    let data = await _fetch(`${api_url}network/core-achiever`, "GET", {});
    if (data?.status === 'success') {
      setCoreAchievers(data?.data);
      setMessage(false);
    }
  }

  const [currentMonthProfit, setCurrentMonthProfit] = useState(0);
  const [lastMonthProfit, setLastMonthProfit] = useState(0);
  // const [yesterdayProfit, setYesterdayProfit] = useState(0);
  const [todayProfit, setTodayProfit] = useState(0);
  const [totalReStake, setTotalReStake] = useState(0);

  const getUserData = async () => {
    // setLoading(true);

    let res = await _fetch(`${api_url}profile/dashboard`, "GET", {});
    let incomeRes = await _fetch(`${api_url}profile/income-summary`, "GET", {})

    if (res?.status == "success") {
      setLoading(false);
      setAllUserData(res?.data);
      // setBussinessData(res?.data?.business?.business);
      settotalTeam(res?.data?.team);


    }
    if (incomeRes?.status == "success") {
      setCurrentMonthProfit(incomeRes?.data?.currentMonthProfit);
      setLastMonthProfit(incomeRes?.data?.lastMonthProfit);
      // setYesterdayProfit(incomeRes?.data?.yesterdayProfit);
      setTodayProfit(incomeRes?.data?.todayProfit);
    }
  }

  const getREstakeData = async () => {
    let res = await _fetch(`${api_url}user/restakeAmount`, "GET", {})

    if (res?.status === "success") {
      setTotalReStake(res?.data?.totalActivationAmount);
    }
  }

  const getPackage = async () => {
    let res = await _fetch(`${api_url}investment/get-package`, "GET", {})

    if (res?.status === 'success') {
      setStarterPackage(res?.Package?.[0]);
    }
  }

  const data1 = [
    ["Task", "Hours per Day"],
    ["XRP", 11],
    ["Bitcoin", 2],
    ["Cardano", 2],
    ["Etherium", 2],

  ];

  const options = {
    title: "Current Incomes",
  };
  const data = [
    ["Task", "Hours per Day"],
    ["Direct Profit", allUserData?.directIncome],
    ["Layered Investment Returns", allUserData?.levelIncome],
    ["Profit on Investment", allUserData?.roiIncome],
    ["Royalty Profit", allUserData?.royaltyIncome],
    ["Eternal Global Pool Profit", allUserData?.poolIncome],

  ];


  const options1 = {
    title: "Trading",
    pieHole: 0.4,
    is3D: false,
  };

  const now = 60;

  // const [info, setInfo] = useState({ sponsor_id: "", wallet_address: "", user_info: { total_package: "" }, created_at: "" ,active_status: false});

  // const getUserInfo = async () => {
  //   let res = await _fetch(`${api_url}user/userInfo/`, "GET", {});
  //   if (res?.status === 'success') {
  //   }
  // }

  const [open3, setOpen3] = useState(false);

  const getBannerData = async () => {
    let res = await _fetch(`${api_url}profile/bannerdata`, "GET", {})

    if (res?.status === "success") {
      setOpen3(res?.data?.[0]?.user_banner?.banner_status);
      setBannerData(res?.data?.[0]?.user_banner);
    }
  }

  const getNewsData = async () => {
    let res = await _fetch(`${api_url}profile/newsData`, "GET", {})

    if (res?.status === "success") {
      setNewsData(res?.data);
    }
  }


  const closeModal3 = () => {
    setOpen3(false);
  };


  const [coinPrice, setcoinPrice] = useState(0);
  const getCoinPrice = async () => {
    let res = await _fetch(`${api_url}profile/tokenValue`, "GET", {})

    if (res?.status === 'success') {
      setLoading(false);

      setcoinPrice(res?.tokenValue);

    }
  }



  // const percentage = (currentAmount / maxAmount) * 100;

  const percentage = (0 / 100) * 100;

  const [activationHistory, setActivationHistory] = useState([{ downid: '', package: 0, date: '' }]); // { downid: '', package: 0, date: '' }

  const getActivationDetails = async () => {
    let response = await _fetch(`${api_url}sponsor/todayActivationHistory`, 'GET', {});

    if (response?.status == 'success') {
      setActivationHistory(response?.data)
    }
  }

  const getWeekTotalIncome = async () => {
    let response = await _fetch(`${api_url}report/last7daysTotalIncome`, 'GET', {});

    if (response?.status == 'success') {
      setWeekTotalIncome(response?.data);
    }
  }

  const [isBuyPrice, setBuyPrice] = useState('')
  const [isBuyAmount, setBuyAmount] = useState(0)
  const onBuyVista = async (type: any) => {

    let postData = {
      vista_amount: isBuyAmount
    }
    // let response = await _fetch(`${api_url}bidding/buy-request`, 'POST', postData, {});
    // if (response?.status === 'success') {
    //   toasted.success(response?.message)
    //   setBuyAmount('');
    //   getOrders()
    // } else {
    //   toasted.error(response?.message)
    // }

  }

  const [isSellPrice, setSellPrice] = useState('')
  const [isSellAmount, setSellAmount] = useState('')

  const onSellVista = async (type: any) => {

    let postData = {
      vista_amount: isSellAmount
    }
    let response = await _fetch(`${api_url}bidding/sell-request`, 'POST', postData, {});

    if (response?.status === 'success') {
      setSellAmount('')
      getOrders()
      toasted.success(response?.message)
    } else {
      toasted.error(response?.message)
    }

  }
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [orderBook, setOrderBook] = useState([])

  const getOrders = async () => {
    let res = await _fetch(`${api_url}bidding/orderbook?page=${page}&limit=${limit}`, "GET", {}, {});

    if (res?.status === 'success') {
      setOrderBook(res?.data);
      setTotalRecords(res?.total);
    } else {
      toasted.error(res?.message)
    }

  }
  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };

  // useEffect(() => {
  //   getOrders()
  // }, [page])

  const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 30 days
  const targetTime = new Date(active_status?.created_at)?.getTime() + oneMonthInMilliseconds;

  // const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  // function calculateTimeRemaining() {

  //   const currentTime = Date.now();
  //   const timeDifference = targetTime - currentTime;

  //   const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
  //   const hours = Math.floor((timeDifference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  //   const minutes = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));
  //   const seconds = Math.floor((timeDifference % (60 * 1000)) / 1000);
  //   return {
  //     days,
  //     hours,
  //     minutes,
  //     seconds,
  //   }
  // }

  // useEffect(() => {
  //   // if (active_status) {
  //   // const interval = setInterval(() => {
  //   //   setTimeRemaining(calculateTimeRemaining());
  //   // }, 1000);

  //   // Cleanup the interval when the component is unmounted
  //   // return () => clearInterval(interval);
  //   // }

  // }, [active_status.created_at,sponsorId]);

  return (
    <>
      <main className='spacing_sec'>
        <div>
          <div className="coustom_container">
            <div className="main_inner_sec ">


              <div className='loader_main'>
                {/* {loading &&
                  <div className="spinner_bg">

                    <div className="color_ring">  <ColorRing
                      visible={true}
                      height="80"
                      width="80"

                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                    /></div>

                  </div>
                } */}
                {/* {newsData?.status && */}

                <div className="banner-section-top">


                  <div className='row '>
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row banner_row">
                            <div className="col-lg-6 col-md-6 ">
                              <div className="logo_sec">
                                <img src={logo_2} className="banner-img" alt="Covido logo" />
                                <p>Live Rate <br /> <span>{coinPrice} USDT/VISTA</span></p>
                              </div>

                            </div>
                            {/* <div className="col-lg-4 col-md-3 col-sm-6">
                                <div className="ranks">
                                  <div className="rank1">
                                    <p>Rank</p>
                                    <h6>0</h6>
                                  </div>
                                  <div className="rank1">
                                    <p>Staked</p>
                                    <h6>0</h6>
                                  </div>

                                </div>
                              </div> */}
                            <div className="col-lg-6 col-md-6 ">
                              <div className="buttons_new">
                                {/* {!userActive && <>
                                  {timeRemaining ?
                                    <button
                                      className='button_style'>
                                      Days left for booster income:
                                      {" "}{timeRemaining?.days}:
                                      {timeRemaining?.hours}:
                                      {timeRemaining?.minutes}:
                                      {timeRemaining?.seconds}
                                    </button>
                                    : null}
                                </>
                                } */}
                                <button className='button_style' onClick={onOpenModal1}>{active_status?.active_status === false ? 'Stake' : 'Restake'}  VISTA </button>
                                <button className='button_style'>Vista left <span>{totalLeftBalance >= 0 ? totalLeftBalance?.toFixed(2) : 0.00}</span> </button>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      {newsData?.status && (
                        <>
                          <div className='d-flex align-items-center mt-2'>
                            <p className='mb-0 me-1'> <VolumeUpRounded /></p>
                            <Marquee className='marquuqqq'>
                              <p className='m-1 mb-0 '>
                                <b>{newsData?.title}: </b><span>{newsData?.description}</span>
                              </p>
                            </Marquee>
                          </div>
                        </>
                      )}

                    </div>

                    {/* <img src={light_banner1} className="banner-img" alt="Covido logo" /> */}
                  </div>
                </div>

                {/* } */}

                {/* second section */}
                <div className='loader_main'>
                  {/* {loading &&
                    <div className="spinner_bg">

                      <div className="color_ring">  <ColorRing
                        visible={true}
                        height="80"
                        width="80"

                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                      /></div>

                    </div>
                  } */}
                  <div className='cards_row'>
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'>
                              <img src={team_vista} className="banner-img" alt="Covido logo" />
                            </div>
                            <div>
                              <h6 className="card-title">My Team</h6>
                              <p className="card-text">{totalTeam}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'>
                              <img src={overview102} className="banner-img" alt="Covido logo" />
                            </div>
                            <div>
                              <h6 className="card-title">My Business</h6>
                              <p className="card-text">{(teamBusinesses?.direct_business + teamBusinesses?.team_business)}</p>
                            </div>
                          </div>
                        </div>

                      </div>


                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'>
                              <img src={income} className="banner-img" alt="Covido logo" />
                            </div>
                            <div>
                              <h6 className="card-title">Today Total Income</h6>
                              <p className="card-text">{`$${Number(todayProfit)?.toFixed(2)}`}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'>
                              <img src={income} className="banner-img" alt="Covido logo" />
                            </div>
                            <div>
                              <h6 className="card-title">Total Income</h6>
                              <p className="card-text">{`$${Number(income_info?.staking_incentive + income_info?.level_business_incentive + income_info?.direct_business_incentive + income_info?.permanent_reward + income_info?.royality_incentive + income_info?.difference_business_incentive)?.toFixed(2)}`}</p>

                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >


                          <div className="card-body">
                            <div className='card_img_outer'><img src={usd} className="banner-img" alt="Covido logo" /></div>
                            <div>
                              <h6 className="card-title">Available Income</h6>
                              <p className="card-text">{allUserData?.avaliableIncome >= "0" ? `$${Number(allUserData?.avaliableIncome)?.toFixed(2)}` : '-'}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'>  <img src={profit2} className="banner-img" alt="Covido logo" /></div>

                            <div>
                              <h6 className="card-title">Staking Incentive</h6>
                              <p className="card-text">{income_info?.staking_incentive >= "0" ? `$${Number(income_info?.staking_incentive)?.toFixed(2)}` : "-"}</p>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">Reward</h6>
                              <p className="card-text">{income_info?.permanent_reward >= "0" ? `$${Number(income_info?.permanent_reward)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>


                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >


                          <div className="card-body">
                            <div className='card_img_outer'> <img src={bi} className="banner-img" alt="Covido logo" /></div>
                            <div>
                              <h6 className="card-title">Direct Business Incentive</h6>
                              <p className="card-text">
                                {income_info?.direct_business_incentive >= "0" ? `$${Number(income_info?.direct_business_incentive)?.toFixed(2)}` : "-"}</p>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={bi1} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">Level Business Incentive</h6>
                              <p className="card-text">{income_info?.level_business_incentive >= "0" ? `$${Number(income_info?.level_business_incentive)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={bi2} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">Royality Incentive</h6>
                              <p className="card-text">{income_info?.royality_incentive >= "0" ? `$${Number(income_info?.royality_incentive)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={bi3} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">Difference Business Incentive</h6>
                              <p className="card-text">{income_info?.difference_business_incentive >= "0" ? `$${Number(income_info?.difference_business_incentive)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={bi3} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">Team ReStaking Business </h6>
                              <p className="card-text">{totalReStake >= 0 ? `$${Number(totalReStake)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>



                      {/* <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">Staking Incentive</h6>
                              <p className="card-text">{allUserData?.directIncome >= "0" ? `$${Number(allUserData?.directIncome)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">Level Income Incentive</h6>
                              <p className="card-text">{allUserData?.levelIncome >= "0" ? `$${Number(allUserData?.levelIncome)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">Profit on Investment</h6>
                              <p className="card-text">{(allUserData?.roiIncome >= "0") ? `$${Number(allUserData?.roiIncome)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">Royalty Profit</h6>
                              <p className="card-text">{allUserData?.royaltyIncome >= "0" ? `$${Number(allUserData?.royaltyIncome)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">Eternal Global Pool Profit</h6>
                              <p className="card-text">{allUserData?.poolIncome >= "0" ? `$${Number(allUserData?.poolIncome)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div> */}

                    </div>
                  </div>
                </div>


                {/* profit cards  */}


                <div className="profit_cards">
                  <div className="row">

                    <div className='col-12 total-limit-sec-outer'>
                      <div className="card total_limit">
                        <div className="card-body">
                          <h6>Total limit : <span>${allUserData?.userInfo?.user_info?.income_limit}</span></h6>

                          <div className='new-bar'>
                            <div id="slider_thumb" className="range-slider_thumb"> {allUserData?.userInfo?.user_info?.income_earn >= "0" ? `${Number(allUserData?.userInfo?.user_info?.income_earn).toFixed(2)}` : "0"}</div>
                            <div className='bar-out'>

                              <input type="range" id="vol" name="vol" min="0" value={allUserData?.userInfo?.user_info?.income_earn >= "0" ? `${Number(allUserData?.userInfo?.user_info?.income_earn).toFixed(2)}` : "0"} max={allUserData?.userInfo?.user_info?.income_limit} />
                              {/* <input type="range" id="vol" name="vol" min="0" value="100" max="200" /> */}
                            </div>

                          </div>



                        </div>
                      </div>
                    </div>


                    <div className="col-lg-4">
                      {/* <div className="card card_inner" >

                        <div className="card-body">
                          <div className="profit_head">
                            <h5 className="card-title">Profit November</h5>
                            <p className="card-text">5.66% </p>
                          </div>
                          <div className='trade_head'>
                            <h6>Total Trades:</h6><h6>8,347</h6>
                          </div>
                          <p className='data'>Data since October 26, 2023</p>
                          <div className='trade_head'>
                            <h6>Winning trade</h6><h6>Avg.Profit / trade</h6>
                          </div>

                          <div className='trade_head'>
                            <h6><NorthIcon className='arrow_up' /><span className='amount'>5,277</span>73.09%</h6><h6>0.001%</h6>
                          </div>
                          <div className='trade_head'>
                            <h6>Losing trade</h6><h6>W / L Ratio</h6>
                          </div>
                          <div className='trade_head'>
                            <h6><SouthIcon className='arrow_down' /><span className='amount_new'>5,277</span>73.09%</h6><h6>0.001%</h6>
                          </div>

                        </div>
                      </div> */}

                      <div className="card bid_card">
                        <div className="card-body">
                          <h5>Bidding System</h5>

                          <Tabs>
                            <TabList>
                              <Tab>BUY </Tab>
                              <Tab className="react-tabs__tab sell-tabs">SELL</Tab>
                            </TabList>

                            <TabPanel>
                              {/* <div className='tabs-data'>
                                <form>
                                  <div className='bidding_sec'>
                                    <span>
                                      Vista
                                    </span>
                                    <input type='number' placeholder='Vista' value={(Math.abs(isBuyAmount / coinPrice))?.toFixed(4)} readOnly />
                                  </div>
                                  <div className='bidding_sec'>
                                    <span>
                                      Price
                                    </span>
                                    <input type='number' placeholder={coinPrice + 'USDT/VISTA'} readOnly />
                                  </div>
                                  <div className='bidding_sec'>
                                    <span>
                                      USDT
                                    </span>
                                    <input type='number' placeholder='Enter Amount' value={isBuyAmount} onChange={(e: any) => { e.target.value >= 0 ? setBuyAmount(e.target.value) : toasted.error('Please enter a valid amount!'); }} />
                                  </div>

                                  <div className='buttonsj-group'>
                                    <button type='button' className='btn buy-btn' onClick={onBuyVista}>BUY</button>

                                  </div>
                                </form>
                              </div> */}
                              <BuyBid maskData={props?.maskData} coinPrice={coinPrice} user_wallet={active_status?.wallet_address} />
                            </TabPanel>
                            <TabPanel>
                              <SellBid maskData={props?.maskData} coinPrice={coinPrice} user_wallet={active_status?.wallet_address} VistaContractAbi={props.VistaContractAbi} />
                            </TabPanel>
                          </Tabs>



                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">

                      <div className="row total-limit-sec-inner">
                        <div className="col-lg-12">
                          <div className="card total_limit">
                            <div className="card-body">

                              <h6>Total limit : <span>${allUserData?.userInfo?.user_info?.income_limit}</span></h6>

                              {/* <div style={{ border: '1px solid black', width: '100%', padding: '5px' }}>
                                <div style={{ width: `${percentage}%`, backgroundColor: 'blue', height: '20px' }}></div>
                                <div style={{ textAlign: 'right' }}>
                                </div>
                              </div> */}
                              <div className="w-100">
                                <div className='new-bar mb-3'>
                                  <div id="slider_thumb" className="range-slider_thumb"> {allUserData?.userInfo?.user_info?.income_earn >= "0" ? `${Number(allUserData?.userInfo?.user_info?.income_earn).toFixed(2)}` : "0"}</div>
                                  <div className='bar-out'>

                                    <input type="range" id="vol" name="vol" min="0" value={allUserData?.userInfo?.user_info?.income_earn >= "0" ? `${Number(allUserData?.userInfo?.user_info?.income_earn).toFixed(2)}` : "0"} max={allUserData?.userInfo?.user_info?.income_limit} />
                                    {/* <input type="range" id="vol" name="vol" min="0" value="100" max="200" /> */}

                                  </div>

                                </div>
                                <div>
                                  {timer > 0 && Number(income_info?.direct_business_incentive) < 5000 ? (
                                    <p className='m-0'>Timer: {formatTime(timer)}</p>
                                  ) : (
                                    timer > 0 ? (
                                     ''
                                    ) : (
                                     ''
                                    )
                                  )}
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row profit-sec-row">
                        {/* <div className="col-lg-6 col-md-6">
                          <div className="card profit_inner_card card_2" >

                            <div className="card-body">
                              <div className="icon_profit">
                                <img src={profit3} className="profit" alt="Covido logo" />
                              </div>
                              <div className="card_data">
                                <h5 className="card-title">Profit</h5>
                                <p className="card-text">Since inception</p>
                                <h6>${currentMonthProfit ? currentMonthProfit.toFixed(4) : currentMonthProfit}</h6>
                              </div>


                            </div>
                          </div>
                        </div> */}
                        {/* <div className="col-lg-6 col-md-6">
                          <div className="card profit_inner_card card_2" >

                            <div className="card-body">
                              <div className="icon_profit">
                                <img src={profit5} className="profit" alt="Covido logo" />
                              </div>
                              <div className="card_data">
                                <h5 className="card-title">Profit</h5>
                                <p className="card-text">Last Month</p>
                                <h6>${lastMonthProfit ? lastMonthProfit.toFixed(4) : lastMonthProfit}</h6></div>
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="col-lg-6 col-md-6">
                          <div className="card profit_inner_card card_3" >

                            <div className="card-body">
                              <div className="icon_profit">
                                <img src={hour24} className="profit" alt="Covido logo" />
                              </div>
                              <div className="card_data">
                                <h5 className="card-title">Profit</h5>
                                <p className="card-text">24 Hour</p>
                                <h6>${yesterdayProfit ? yesterdayProfit.toFixed(4) : yesterdayProfit}</h6>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="col-lg-6 col-md-6">
                          <div className="card profit_inner_card card_4" >

                            <div className="card-body">
                              <div className="icon_profit">
                                <img src={profit4} className="profit" alt="Covido logo" />
                              </div>
                              <div className="card_data">
                                <h5 className="card-title">Profit</h5>
                                <p className="card-text">Currently</p>
                                <h6>${todayProfit ? todayProfit.toFixed(4) : todayProfit}</h6>
                              </div>
                            </div>
                          </div>
                        </div> */}


                      </div>
                    </div>
                  </div>
                </div>


                {/* Rank Royality */}
                <div className="rank_royality">
                  <div className="row">

                    <div className="col-lg-4 ">
                      <h4>
                        {allUserData?.userInfo?.user_info?.firstName ? (allUserData?.userInfo?.user_info?.firstName + ' ' + allUserData?.userInfo?.user_info?.lastName) : 'no name'}
                      </h4>
                      <div className="card rank_card mb-3">
                        <div className="card-body d-inline-block">
                          <div className="executive_icon d-flex mb-2">
                            {/* <AccountCircleIcon /> */}
                            <h6 className="card-title ml-2">My Address</h6>

                          </div>
                          <div className="current_rank">
                            <p className="card-text">{active_status?.wallet_address}</p>
                          </div>
                        </div>
                      </div>
                      <div className="card rank_card">
                        <div className="card-body">
                          <div className="executive_icon">
                            <MonetizationOnIcon />
                            {/* <img src={dollar_image} className="banner-img" alt="Covido logo" /> */}
                          </div>
                          <div className="current_rank">
                            <h6 className="card-title">My Package</h6>
                            <p className="card-text">{`$${allUserData?.userInfo?.user_info?.package?.total_package}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="right_rank_sec">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="card">
                              <div className="card-body">
                                <div className="rank_amount">
                                  <h6>My Rank</h6>
                                  {/* <h6>$0</h6> */}
                                </div>
                                <div className="rank_amount">
                                  <h6>Current Rank ({userRank?.rank_name})</h6>
                                  {/* <h6>$0</h6> */}
                                </div>
                                <div className="rank_amount">
                                  <h6>Next Rank ({nextRank})</h6>
                                  {/* <h6>$0</h6> */}
                                </div>
                                {/* <div className="progress_sec">
                                  <p>Completed</p>
                                  <ProgressBar now={now} label={`${now}%`} />
                                </div> */}

                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card">
                              <div className="card-body">
                                <div className="inner_data_values">
                                  <div><h6>Main Leg (King Maker)</h6></div>
                                  <div className="item-sec">
                                    <p>{bussinessData?.firstleg - PreviousMaxKingMaker}</p>
                                    <p>Max {MaxKingMaker}</p>
                                  </div>

                                </div>
                                <div className="inner_data_values">
                                  <div><h6>Leg 2</h6></div>
                                  <div className="item-sec">
                                    <p>{bussinessData?.secondleg - PreviousMaxSecondLeg}</p>
                                    <p>Max {MaxSecondLeg}</p>
                                  </div>

                                </div>
                                <div className="inner_data_values">
                                  <div><h6>Other</h6></div>
                                  <div className="item-sec">
                                    <p>{bussinessData?.thirdleg - PreviousMaxOther}</p>
                                    <p>Max {MaxOther}</p>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>
                </div>

                {/* charts */}
                <div className="chart_sections d-none">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="card mb-3">
                        <div className="card-body">
                          <Chart
                            chartType="PieChart"
                            data={data}
                            options={options}
                            width={"100%"}
                            height={"400px"}
                            className='chart_style'
                          />
                        </div></div>

                    </div>
                    <div className="col-lg-6">
                      <div className="card">
                        <div className="card-body">


                          <Chart
                            chartType="PieChart"
                            width="100%"
                            height="400px"
                            data={data1}
                            options={options1}
                          />  </div>
                      </div>

                    </div>
                  </div>
                </div>





                {/* history */}
                <div className="performance_cards">
                  <div className="row">

                    {/* <div className="col-lg-4">
                      <div className="card left_card mb-3">
                        <div className="card-body">

                          <h5>Past Performance</h5>
                          <div className="list_sec">
                            <p>June, 2023</p>
                            <p className='percentage'>9.06%</p>
                            <p><FileCopyIcon /></p>
                          </div>
                          <div className="list_sec">
                            <p>July, 2023</p>
                            <p className='percentage'>5.00%</p>
                            <p><FileCopyIcon /></p>
                          </div>
                          <div className="list_sec">
                            <p>Aug, 2023</p>
                            <p className='percentage'>3.07%</p>
                            <p><FileCopyIcon /></p>
                          </div>



                        </div>
                      </div>
                    </div> */}

                    {/* <div className="col-12">
                      <div className="card order_card mb-3 card_auto_hed_d">
                        <div className='card-header'>
                          <h5 className='mb-2'>Almaty {title} Summit 2024 </h5>
                        </div>

                        <div className="card-body card_auto_hed">
                          <div className='scrolling_table_new'>
                            <table className='table table-sec border-0 mb-0'>
                              <thead>
                                <th>Required Business </th>
                                <th>Achieved Business </th>
                                <th>Total seat </th>
                                <th>Pending seat </th>
                              </thead>

                              <tbody>
                                <tr>
                                  <td> $7000 </td>
                                  <td> ${summitAchiever?.business} </td>
                                  <td> 50 </td>
                                  <td> {50 - summitAchiever?.user} </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </div> */}
                    {!getArchieverData?.soft_status && (
                      <>
                        <div className="col-12">
                          <div className="card order_card mb-3 card_auto_hed_d p-3 justify-content-center">
                            <div className='card-header border-0 d-flex justify-content-between p-0 m-0 align-items-center'>
                              <h5 className='mb-2'>Expo Archiever</h5>
                              {getArchieverData ? (
                                archieverPhone ? (
                                  <p className="card-text text-white">Congratulations, your Seat No # {seatno}</p>
                                ) : (
                                  <Link className='open-form text-white' to='/create-archiever'>Book Your Seat</Link>
                                )
                              ) :
                                <p className="card-text text-white">Pending</p>
                              }

                            </div>

                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-12">
                      <div className="card order_card mb-3 card_auto_hed_d">
                        <div className='card-header'>
                          <h5 className='mb-2'>Core Archiever </h5>
                        </div>
                        {message !== true ? (
                          <div className="card-body card_auto_hed">
                            <div className='scrolling_table_new'>
                              <table className='table table-sec border-0 mb-0'>
                                <thead>
                                  <th># </th>
                                  <th>Rank </th>
                                  <th> Required Business </th>
                                  <th> Total Archiever </th>
                                  <th> Pending Archiever </th>
                                  <th> Main Leg </th>
                                  <th> Second Leg </th>
                                  <th> Other </th>
                                </thead>
                                <tbody>
                                  {coreAchievers ?
                                    <tr>
                                      <td>1</td>
                                      <td>{coreAchievers?.rank} </td>
                                      <td> {coreAchievers?.totalrequiredbusiness ? coreAchievers?.totalrequiredbusiness : 0} </td>
                                      <td> {coreAchievers?.totalarchievers} </td>
                                      <td> {coreAchievers?.pendingArchievers} </td>
                                      <td> {coreAchievers?.selfBusiness?.business?.firstleg ? coreAchievers?.selfBusiness?.business?.firstleg : 0} </td>
                                      <td> {coreAchievers?.selfBusiness?.business?.secondleg ? coreAchievers?.selfBusiness?.business?.secondleg : 0} </td>
                                      <td> {coreAchievers?.selfBusiness?.business?.thirdleg ? coreAchievers?.selfBusiness?.business?.thirdleg : 0} </td>
                                    </tr>
                                    : ''}
                                </tbody>
                              </table>
                            </div>

                          </div>
                        ) : (
                          <div className='card-body card_auto_hed text-center text-light mt-2'>Please wait, looking for achiever</div>
                        )}
                      </div>
                    </div>


                    <div className="col-12">
                      <div className="card order_card mb-3">
                        <div className='card-header'>
                          <h5 className='mb-2'>Order Book </h5>
                        </div>

                        <div className="card-body">
                          <table className='table'>
                            <thead>
                              <th>Price </th>
                              <th>USDT </th>
                              <th>Vista </th>
                              <th>Status </th>

                            </thead>
                            {orderBook.map((item: any, index: any) => {
                              return (
                                <>
                                  <tbody>
                                    <tr className={item?.type === "sell" ? 'loss-user' : 'profit-user'}>
                                      <td>{item?.vista_price} </td>
                                      <td>{item?.usdt_amount} </td>
                                      <td>${item?.vista_amount?.toFixed(4)} </td>
                                      <td className={item?.status === 'Approved' ? 'text-success' : item?.status === 'Pending' ? 'text-info' : ''}>{(item?.status)} </td>

                                    </tr>
                                  </tbody>
                                </>
                              )
                            })}

                          </table>
                        </div>
                        {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                          breakLabel="..."
                          nextLabel=">"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={1}
                          pageCount={totalRecords / 10}
                          previousLabel="<"
                        />}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="card order_card mb-3">
                        <div className='card-header'>
                          <h5 className='mb-2'>Today Active Team </h5>
                        </div>

                        <div className="card-body">
                          <div className='scrolling_table_new'>
                            <table className='table table-sec'>
                              <thead>
                                <th>Username </th>
                                <th>Level </th>
                                <th>Package </th>
                                <th>Date </th>
                              </thead>

                              {activationHistory?.map((item: any, index) => {

                                return (
                                  <tbody>
                                    {activationHistory.length > 0 &&
                                      <>
                                        <tr>
                                          <td>{item?.downid} </td>
                                          <td>{item?.level} </td>
                                          <td>${item?.package} </td>
                                          <td>{dateformat(item?.date, 'UTC:dd-mm-yyyy hh:mm:ss TT')} </td>
                                        </tr>
                                      </>
                                    }
                                  </tbody>

                                )
                              })}

                            </table>
                          </div>

                        </div>
                      </div>
                    </div>


                    <div className="col-12">
                      <div className="card order_card mb-3">
                        <div className='card-header'>
                          <h5 className='mb-2'>Week Total Income </h5>
                        </div>

                        <div className="card-body">
                          <div className='scrolling_table_new'>
                            <table className='table table-sec'>
                              <thead>
                                <th>Date</th>
                                <th>Total Income </th>
                              </thead>

                              {weekTotalIncome?.map((item: any, index: any) => {

                                return (
                                  <tbody>
                                    {weekTotalIncome.length > 0 &&
                                      <>
                                        <tr>
                                          <td>{item?.date} </td>
                                          <td>${(item?.totalIncome)?.toFixed(4)} </td>
                                        </tr>
                                      </>
                                    }
                                  </tbody>
                                )
                              })}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className='col-lg-6'>
                      <div className='card investment-card'>

                        <div className='card-header'>
                          <h5 className='mb-2'>investment history </h5>
                        </div>

                        <div className='card-body'>
                          <div className="table_section">
                            <div className="scrolling_table">
                              <div className="scroll_under">
                                <Table className="support_table">
                                  <thead>
                                    <tr className="table_heading_row">
                                      <th className="table_heading"> #</th>
                                      <th className="table_heading"> Amount</th>
                                      <th className="table_heading">Scan</th>
                                      <th className="table_heading">Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    {fundValues?.map((item: any, index: any) => {
                                      return (
                                        <>
                                          <tr className="table_data_row">
                                            <td className="table_data">{index + 1}</td>
                                            <td className="table_data">${item?.amount?.toFixed(2)}</td>
                                            <td className="table_data payment_fee">
                                              <Link to={item?.blockscan_url} className="button_style" target="_blank"> View </Link>
                                            </td>
                                            <td className="table_data payment_fee">{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                          </tr>
                                        </>
                                      )
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div> */}
                    {/* <div className="col-lg-6">
                      <div className="card right_sec mb-3">
                        <div className="card-body">
                          <h5>History - October</h5>
                          <div className="profit_month">
                            <p>Profit October</p>
                            <p>9.06%</p>
                          </div>
                          <div className="inner_img_sec">
                            <div className="img_icons">
                              <img src={btc_img} alt="btc" />
                              <p>BTC</p>
                            </div>
                            <div className="img_icons">
                              <img src={eth} alt="eth" />
                              <p>ETH</p>
                            </div>
                            <div className="img_icons">
                              <img src={bnb_img} alt="bnb" />
                              <p>BNB</p>
                            </div>
                            <div className="img_icons">
                              <img src={xrp} alt="xrp" />
                              <p>XRP</p>
                            </div>
                          </div>
                          <button className='button_style'>Report</button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                {/* table section */}

              </div>
              <div className="header-left mt-3">
                <div className="link-copy ">
                  <input type='text' value={`${website}register/${sponsorId}`} disabled></input>
                  <div className="icon-copy">
                    <FileCopyIcon onClick={() => { copyToClipboard(`${website}register/${sponsorId}`) }} />
                  </div>
                </div>
                <div className="icons_im">
                  {/* <img src={scan_code} className="scan_code" alt="scan" onClick={onOpenModal} /> */}

                  {/* 
                  <Modal open={open} onClose={onCloseModal} center classNames={{
                    modal: "qr_scan_modal",
                  }}>
                    <QRCode className="scan_modal" value={`${website}register/${sponsorId}`} />
                  </Modal> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </main >

      {/* {open3 && (
        // {bannerData?.banner_status === true ? (
        <Modal open={open3} onClose={closeModal3} center
          classNames={{
            modal: "homepage_modal",
          }}>
          <div className="banner_modal">
            <img src={bannerData?.banner} alt={bannerData?.description} className='banner_img' />
            <h6 className='modal-heading'>{bannerData?.description}</h6>
          </div>
        </Modal>
        //  ) : (
        //   null // You can replace this with any other content or leave it as an empty string
        // )}
      )} */}




      {/* {archieverPhone ? (
        <Modal show={show} onHide={handleClose} style={{ display: show ? 'block' : 'none', position: 'absolute' }}>
          <Modal.Dialog>
            <Modal.Header onClick={handleClose}>
              <Button variant="close" onClick={handleClose} />
            </Modal.Header>

            <Modal.Body>
              <h1 className='congra'>Congratulations your Seat No # {seatno}</h1>
            </Modal.Body>

            <Modal.Footer>
            </Modal.Footer>
          </Modal.Dialog>
        </Modal>
      ) : (
        <Modal show={show} onHide={handleClose} style={{ display: show ? 'block' : 'none', position: 'absolute' }}>
          <Modal.Dialog>
            <Modal.Header onClick={handleClose}>
              <Button variant="close" onClick={handleClose} />
            </Modal.Header>

            <Modal.Body>
              <h1 className='congra'>Vista Expo Archiever</h1>
            </Modal.Body>

            <Modal.Footer>
              <Link className='open-form' to='/create-archiever'>Book Your Seat</Link>
            </Modal.Footer>
          </Modal.Dialog>
        </Modal>
      )} */}


      <Modal open={open1} onClose={onCloseModal1} center>
        {active_status?.active_status === false ? <StakeCoin coinPrice={coinPrice} package={starterPackage?._id} userName={sponsorId} onCloseModal={onCloseModal} maskData={props.maskData} user_wallet={active_status?.wallet_address} /> : <RestakeCoin coinPrice={coinPrice} package={starterPackage?._id} userName={sponsorId} onCloseModal5={onCloseModal1} maskData={props.maskData} user_wallet={active_status?.wallet_address} VistaContractAbi={props.VistaContractAbi} />}
      </Modal>

    </>
  )
}

export default Dashboard
